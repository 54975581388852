.footer {
    font-family: 'Montserrat', sans-serif;
    background-color: $background-light;
    //box-shadow: 0px 0px 10px -5px $background-dark;
}

.footer .title {
    font-size: 1.05em;
    font-weight: bold;
}

.footer .links {
    padding: 30px 130px;
    font-weight: 300;
}

.links ul {
    margin: 0;
    padding-left: 1em;
    color: $font-bullets;
    list-style: none;
}

.links ul li {
    vertical-align: middle;
    line-height: 2em;
    position: relative;
}

ul li:before {
    content: "•";
    font-size: 1.5em;
    padding-right: 5px;
    position: absolute;
    left: -0.9em;
    top: -0.03em;
    color: #C27F76;
}

.links a {
    font-size: 0.8em;
    vertical-align: top;
    color: $font-primary;
    text-decoration: none;
    cursor: pointer;
}

.links a:hover {
    color: $font-secondary;
}

.links .icons {
    font-size: 1em;
    vertical-align: middle;
    color: $font-social-icon;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.socialM {
    padding: 1em 0;

    .vuela {
        margin-bottom: 5px;
    }
}

.socialM strong {
    color: $font-footer;
    font-weight: 600;
}

.socialM a {
    color: $font-social-icon;
}

.icons img {
    height: 2em;
    margin: 6px;
}

.callCenter {
    display: flex;
    color: $font-footer;
    font-size: 1em;
    font-weight: 500;

    svg {
        font-size: 2.3em;
    }
}

.footer .alliances {
    padding: 0px 10%;
}

.alliances img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.zenkifoo img {
    height: 50px;
}

.alliances .copy-right {
    margin: 30px 0;
    font-size: 0.7em;
}

@media only screen and (max-width: 899px) {
    .footer .links {
        padding: 30px 2%;
    }

    .footer .alliances {
        padding: 0px 2%;
    }

    .alliances .copy-right {
        margin: 15px 0;
    }
}


@media only screen and (max-width: 460px) {

    .footer .title {
        font-size: 1.8em;
    }

    .links a {
        font-size: 1.5em;
    }
}