.Socios {

    .title {
        background-color: $background-titles;
        color: $font-titles;
        display: flex;
        justify-content: center;
        font-size: 2em;
        font-weight: 900;
    }

    .sociosCont {
        width: 25%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;


        .socImg {
            width: 90%;
            height: 25em;
            padding: 27px 10px;
            box-sizing: content-box !important;


            img {
                width: 100%;
                height: 100%;
            }

            .nameSoc {
                position: absolute;
                top: 19em;
                text-align: center;
                justify-content: center;
                display: flex;
                justify-items: center;
                width: 90%;
                color: $font-white;
                font-size: 1.2em;
                font-weight: 900;
            }

            .nameSoc:hover {
                height: 40%;
                top: 13em
            }
        }
    }

}

@media only screen and (max-width: 899px) {
    .Socios {
        .sociosCont {
            .socImg {
                .nameSoc {
                    top: 19.1em; 
                }

                .nameSoc:hover {
                    top: 13em
                }
            }
        }
    }

}

@media only screen and (max-width: 460px) {
    .Socios {
        .sociosCont {
            .socImg {
                .nameSoc {
                    top: 20em; 
                    width: 81%;
                }

                .nameSoc:hover {
                    top: 13em
                }
            }
        }
    }


}

@media only screen and (max-width: 400px) {
    .Socios {
        .sociosCont {
            .socImg {
                .nameSoc {
                    top: 21em;
                    width: 81%;
                }

                .nameSoc:hover {
                    top: 13em
                }
            }
        }
    }
}