.payment {

  margin-bottom: 2em;

  #ibe-payment {
    h1 {
      &.title {
        color: #444;
        font-size: 34px;
        font-weight: 300;
      }
    }

    .cvv-container {
      text-align: center;
      padding: 0 16px;
    }

    p.warnning {
      color: #444;
      font-size: 16px;
      font-weight: 300;

      .important {
        color: #e51f04;
        float: none;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        text-transform: uppercase;
      }
    }

    input {
      margin: 10px 30px 10px 0;
      padding: 0;
      font-weight: 300;
    }

    .expansion-wrapper {
      .summary-wrapper {
        display: flex;
        align-items: center;

        .MuiTypography-root {
          font-size: 24px;
          font-weight: 300;
        }
      }



      .MuiAccordionDetails-root {
        display: block;

        .card-expl {

          img {
            max-width: 100%;
          }

          h4 {
            color: #444;
            font-size: 16px;
            font-weight: 400;
          }

          .card-type {
            padding: 0 15px;
          }

          .credit {
            border-right: 1px solid #ccc;
          }

          .debit {}
        }
      }
    }

    .footer-form-buttons {
      background-color: #F4F4F4;
      height: 7em;
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      border-radius: 1em;

      .footerBtns {
        margin-top: 0;

      }

      .btnsPay {
        width: -webkit-fill-available;
      }

      .footerTotal {
        display: grid;
        align-items: center;
        justify-content: center;
      }


    }

    .footer-form {


      a {
        color: #e51f04;
        float: none;
        font-size: 14px;
        display: inline;
      }
    }

    .agree-promotions {
      cursor: pointer;
      margin: 0 !important;
      font-size: 14px;
      width: auto;
      line-height: 50px;
    }

    .payment-btn-container {
      text-align: right;
    }

    .countryCode {
      color: $font-middle;
      font-weight: 300;
      width: 4.5em;

      .special-label {
        display: none !important;
      }

      input {
        margin: 0;
        width: 100%;
        height: 2.3em;
        border: 0;
        padding: 0;
        pointer-events: none;
        text-align: right;
      }
    }

    .phoneNum {
      width: 100%;
    }

    .flag-dropdown {
      .selected-flag {
        width: 0;
        padding: 0;
      }
    }

  }

  //widget store
  .pay-store img {
    height: 4em;
  }

  .steps-store {
    display: flex;
  }

  .steps img {
    height: 8em;
  }

  .steps {
    display: grid;
    align-items: center;
    justify-items: center;
    text-align: center;
  }

  .alertCvv {
    font-size: 0.9em;

    svg {
      color: #e51f04;
      margin: 0 3px 0 3px;
    }

    label {
      font-size: 0.9em !important;
      font-weight: 500 !important;
    }
  }

  .Mui-selected {
    background-color: transparent !important;
    color: transparent !important;
  }
}

.step-180-seating {
  font-family: "Montserrat";
  display: inline-flex;
  justify-content: center;
  align-items: center;
  align-content: center;

  .MuiBackdrop-root {
    background-color: rgba($color: $background, $alpha: 3/4%);
    justify-content: center;
    backdrop-filter: blur(8px);

  }
}

#step-selection-modal {
  position: relative;
  background-color: #fff;
  border-radius: 2em;
  width: 30%;
  min-height: 250px;
  padding: 3em;
  box-shadow: 0px 0px 13.3px 0px #0000001F;

  .modal-title {
    font-weight: 700;
    font-size: 2em;
    line-height: 26.82px;
  }

  .modal-description {
    font-weight: 400;
    font-size: 1em;
    line-height: 23.52px;
    padding: 1em 0;
    border-bottom: 1px solid #DFDFDF;

    strong {
      color: #E51F04;
    }

  }

  .modal-buttons {
    display: inline-flex;
    padding: 1em 0;

    .btnStep180 {
      border-radius: 7px;
      -webkit-border-radius: 7px;
      -moz-border-radius: 7px;
      gap: 10px;
      text-transform: capitalize;
      font-weight: 600;
      font-size: 0.9em;
    }
  }
}

.segment-modal-payment {
  font-family: "Montserrat";
  display: inline-flex;
  justify-content: center;
  align-items: center;
  align-content: center;

  .MuiBackdrop-root {
    background-color: rgba($color: $background, $alpha: 3/4%);
    justify-content: center;
    backdrop-filter: blur(8px);
  }
}

#payment-selection-modal {
  position: relative;
  background-color: #fff;
  border-radius: 2em;
  width: 40%;
  min-height: 250px;
  padding: 3em;
  box-shadow: 0px 0px 13.3px 0px #0000001F;

  .modal-title {
    font-weight: 700;
    font-size: 1.7em;
    line-height: 26.82px;
  }

  .modal-description {
    font-weight: 400;
    font-size: 1em;
    line-height: 23.52px;
    padding: 1em 0;
    border-bottom: 1px solid #DFDFDF;

    strong {
      color: #E51F04;
    }

    .modal-terms {
      .checkSelected {
        color: #E51F04;
      }
    }

  }

  .modal-buttons {
    display: inline-flex;
    padding: 1em 0;

    .btnSegment {
      border-radius: 7px;
      -webkit-border-radius: 7px;
      -moz-border-radius: 7px;
      gap: 10px;
      text-transform: capitalize;
      font-weight: 600;
      font-size: 0.9em;
    }

    #cancel-btn {
      margin-left: 2em;
    }
  }

}


.modal-Payment {
  font-family: "Montserrat";
  display: inline-flex;
  justify-content: center;
  align-items: center;
  align-content: center;

  .MuiBackdrop-root {
    background-color: rgba($color: $background, $alpha: 3/4%);
    justify-content: center;
    backdrop-filter: blur(8px);
  }

  .cntAlert {
    display: inline-flex;
    justify-content: center;

    .aletrImg {

      .CircularProg {
        width: 5.5em !important;
        height: 5.5em !important;
        position: relative;
        top: -1.6em;
        left: -5.5em;

        svg {
          circle {
            stroke-width: 1.6px;
          }
        }
      }
    }

    #modalAlertPayment {
      width: 50%;

      .alertPayment {
        font-size: 2rem;
        color: $font-primary;

        strong {
          color: #E51F04;
        }
      }
    }

  }
}



.hidde {
  display: none;
}

.PaymentMethods,
.btnCont {
  display: inline-flex;
  margin-top: 1em !important;
}

.btnMethod {
  width: 20em;
  height: 4em;
  border: 1.5px solid #e51f04 !important;
  text-transform: none !important;

  .btnContent {
    display: inline-grid;
    justify-items: center;

    .btnlbl,
    .btnlblrap,
    .btnlblzen {
      opacity: 0;
      height: 0;
    }

    .btnlbl,
    .btnPayOp {
      align-items: center;
      justify-content: center;
      align-self: auto;
    }

    .btnlblrap,
    .btnlblzen {
      align-items: center;
      justify-content: center;
      align-self: auto;
    }

    .btnimg,
    .btnimgZen,
    .btnimgrap {
      align-items: center;
      justify-content: center;
      align-self: flex-end;
      margin: 2em;
      border-radius: 12px;
    }

    .btnimgrap,
    .btnimgZen {
      width: 18em;
    }

    .btnimg {
      width: 11em;
      height: auto;
    }

    .btnPayOp {
      display: inline-flex;
      z-index: 9;

      img {
        width: auto;
        height: 2em;
        padding-left: 1em;
      }
    }
  }
}

.btnimg,
.btnimgrap,
.btnimgZen {
  position: absolute;
  margin: 0.5em;
  background-color: white;
}

.btnMethod:hover {
  height: 8em;

  .btnimg,
  .btnimgrap,
  .btnimgZen {
    position: absolute;
    margin: 5em;
    background-color: white;
  }

  .btnlbl,
  .btnlblrap,
  .btnlblzen {
    opacity: 100%;
    height: 33.33%;
  }

  .btnlblzen img {
    width: 8em;
  }

  .btnPayOp {
    justify-content: center;
    align-items: flex-end;
    align-self: flex-end;
  }

  .errorP {
    color: $border-red;
    font-size: 0.8em;
    margin: 0;
    width: 100%;
  }
}

.phoneCountry {
  display: inline-flex;
  align-items: center;
  width: 100%;

  .countryCode {
    margin: 0;
    width: 24%;
  }

  .special-label {
    display: none !important;
  }

  .form-control {
    width: 100%;
    padding-left: 38px !important;
    padding-right: 5px !important;
    height: 2.6em !important;
    text-align: center;
    border-right-color: transparent !important;
    border-radius: 0 !important;
    pointer-events: none;
    margin: 0 !important;

    &:disabled {
      pointer-events: none;
      cursor: none;
    }

  }


  #phone,
  #cell {
    width: 85% !important;
    margin: 0 !important;
  }


}

.BtnSecondary {
  width: 20em;
  text-transform: none !important;
  font-size: 2em;

  .btnContent {
    display: inline-grid;
    justify-items: center;

    .btnlbl,
    .btnlblrap,
    .btnlblzen {
      opacity: 100%;
      margin-top: 1em;
    }

    .btnlbl,
    .btnPayOp {
      align-items: center;
      justify-content: center;
      align-self: auto;
    }

    .btnlblrap,
    .btnlblzen {
      align-items: center;
      justify-content: center;
      align-self: center;
    }

    .btnlblzen img {
      width: 8em;
    }

    .btnimg,
    .btnimgZen,
    .btnimgrap {
      align-items: center;
      justify-content: center;
      align-self: flex-end;
      margin: 1.5em;
      background-color: #e51f04;
      border-radius: 12px;
    }

    .btnimgrap {
      width: 18em;
      margin: 4em;
    }

    .btnimgZen {
      width: 18em;
      margin: 5em;
    }

    .btnimg {
      width: 11em;
      height: auto;
      margin: 5em;
    }


    .btnPayOp {
      display: inline-flex;
      z-index: 9;

      img {
        width: auto;
        height: 2em;
        padding-left: 1em;
      }
    }
  }
}

.btnCont {
  justify-content: center;
}

.optionsPay {
  padding-top: 2em;
}

.MuiGrid-item {
  .btnRappi {
    margin: 2em 16em;
  }

  .zenkiselected {
    cursor: pointer;
    display: inline-grid;

    .btnPayOp {
      z-index: 9;

      img {
        width: auto;
        height: 2em;
        padding-left: 1em;
      }
    }
  }

  .zenky {
    justify-items: center;
    display: contents;
  }

  .zenkiDisable {
    cursor: auto;

    img {
      width: 100%;
      height: auto;
      padding-left: 1em;
    }
  }

  .zenkiEnabled {
    cursor: pointer;

    img {
      width: 100%;
      height: auto;
      padding-left: 1em;
    }
  }

}

@media only screen and (max-width: 1280px) {

  .MuiGrid-item {}

}


@media only screen and (max-width: 760px) {

  .PaymentMethods,
  .btnCont {
    display: contents;
  }

  .rappiFail {
    margin: 0em;

    .rappi-title {
      margin: 0 4em;
    }

  }

  .MuiGrid-item {
    .btnRappi {
      margin: 2em 2em;
    }
  }
}

@media only screen and (max-width: 460px) {
  #payment-selection-modal {
    width: 92%;
    padding: 2em;
  }

  #step-selection-modal {
    width: 90%;
    padding: 2em;
  }

  .footer-form-buttons {
    justify-content: space-evenly !important;
  }


  input {
    margin: 10px 6px 10px 0 !important;
  }

  .MuiGrid-spacing-xs-4>.MuiGrid-item {
    padding: 1px !important;
    padding-bottom: 16px !important;
  }

  .MuiGrid-spacing-xs-4 {
    width: calc(90% + 32px) !important;
    margin: 3px !important;
  }

  .makeStyles-formControl-342 {
    width: 92% !important;
    min-width: 96px !important;
  }

  .payment #ibe-payment input {
    width: 98%;
  }

  .PaymentMethods,
  .btnCont {
    display: contents;
  }

  .btnMethod,
  .BtnSecondary {
    margin-top: 3em !important;
  }

  .footer-form-buttons {
    justify-content: space-evenly !important;
  }

}

.MuiAppBar-colorPrimary {
  color: transparent !important;
  background-color: transparent !important;
}